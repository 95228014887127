import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory, withRouter } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';

import { DYNAMIC_SEARCH_PATH, LISTING_GIGS_PAGE } from 'settings/constant';
import { NavbarSearchWrapper } from './Header.style';
import { Input } from 'antd';
import { createUrl } from 'components/Search/url_handler';

const fiSearchStyles = {
  WebkitTextSizeAdjust: '100%',
  '-antdWaveShadowColor': '#1890ff',
  '-scrollBar': '0',
  color: 'rgba(0, 0, 0, 0.65)',
  fontSize: '14px',
  fontVariant: 'tabular-nums',
  lineHeight: '1.5715',
  fontFeatureSettings: '\'tnum\', "tnum"',
  fontFamily: "'Lato',sans-serif",
  textShadow: '1px 1px 1px rgba(0,0,0,0.004)',
  WebkitTapHighlightColor: 'transparent',
  stroke: 'currentcolor',
  fill: 'none',
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  boxSizing: 'inherit',
  WebkitFontSmoothing: 'antialiased',
  overflow: 'hidden',
  position: 'absolute',
  zIndex: '1',
  top: 'auto',
  right: '15px',
  width: '20px',
  height: '20px',
};

const NavbarSearch = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const searchHandler = (evt) => {
    setSearchTerm(evt.target.value);
    if (searchTerm.length) {
      const query = createUrl({ keywords: searchTerm });
      console.log(DYNAMIC_SEARCH_PATH(query));
      searchTerm && history.push(`/musicians${query}`);
    }
  };

  const updateValueFunc = (value) => {
    const { searchedPlaceAPIData } = value;
    let tempLocation = [];

    const searchLocation = tempLocation ? tempLocation[0] : {};
    if (!isEmpty(searchLocation)) {
      const state = getStateFromUrl(props.location);
      const query = {
        ...state,
        location: searchLocation,
      };
      const search = setStateToUrl(query);
      props.history.push({
        pathname: LISTING_GIGS_PAGE,
        search: search,
      });
    }
  };

  return (
    <NavbarSearchWrapper className="navbar_search" style={{ marginRight: 1 }}>
      {/* <form onSubmit={(e) => searchHandler(e)}> */}
      <Input
        type="text"
        defaultValue=""
        placeholder="Search “DJs, Disco, Guitarists, 80s”"
        size="large"
        onPressEnter={(evt) => searchHandler(evt)}
      />
      <FiSearch style={fiSearchStyles} />
      {/* </form> */}
    </NavbarSearchWrapper>
  );
};

export default withRouter(NavbarSearch);
