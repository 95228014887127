import * as types from './actionTypes';

const AppReducer = (state, action) => {
  switch (action.type) {
    case types.SET_TITLE: {
      console.log('title success');
      return state;
    }

    case types.CHANGE_TITLE: {
      return {
        ...state,
        performerTitle: action.payload,
      };
    }

    case types.SET_DESCRIPTION:
      return state;

    case types.CHANGE_DESCRIPTION:
      return {
        ...state,
        performerDescription: action.payload,
      };

    case types.FETCH_DATA:
      const { title, content } = action.payload;
      return {
        ...state,
        performerTitle: title,
        performerDescription: content,
      };
    default:
      return state;
  }
};

export default AppReducer;
