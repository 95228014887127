import { Button, notification, Space } from 'antd';

export const openNotificationWithIcon = (type, message) => {
  if (type === 'success') {
    notification[type]({
      placement: 'bottomRight',
      duration: 0,
      message: 'Message Delivered',
    });
  }
  if (type === 'error') {
    notification[type]({
      placement: 'bottomRight',
      duration: 0,
      message: message || 'Specify Gig Id!',
    });
  }
};
