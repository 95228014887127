import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';

import {
  ADD_GIG_PAGE,
  CREATOR_SIGNUP_PAGE,
  HOME_PAGE,
  LISTING_GIGS_PAGE,
  LIVE_STREAM_PLAYBACK_PAGE,
  MUSICIAN_GIGS_PAGE,
  ROLE_CREATOR,
  ROLE_CUSTOMER,
} from 'settings/constant';

const MainMenu = ({ className }) => {
  const { loggedIn, user } = useContext(AuthContext);
  const authUser = user && JSON.parse(user);
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${LISTING_GIGS_PAGE}`}>Musicians</NavLink>
      </Menu.Item>
      {loggedIn && authUser.role === ROLE_CUSTOMER && (
        <Menu.Item key="2">
          <NavLink to={`${LIVE_STREAM_PLAYBACK_PAGE}`}>
            My Upcoming Live Streams
          </NavLink>
        </Menu.Item>
      )}
      {loggedIn && authUser.role === ROLE_CREATOR && (
        <Menu.Item key="3">
          <NavLink to={`${MUSICIAN_GIGS_PAGE}`}>My Gigs</NavLink>
        </Menu.Item>
      )}
      {!loggedIn && (
        <Menu.Item key="4">
          <NavLink to={`${CREATOR_SIGNUP_PAGE}`}>I'm a Musician</NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MainMenu;
