import React, { createContext, useState } from 'react';

const initialState = {
  title: '',
  price: '',
  bookingId: '',
  startTime: '',
  date: '',
  numOfHrs: '',
  gigId: '',
  booleans: {
    makeBookingVar: false,
    singleBookingVar: false,
    bookingsListVar: false,
  },
  bookingId: '',
};

export const BookingContext = createContext(initialState);

const BookingProvider = (props) => {
  const [bookingData, setBookingData] = useState(initialState);
  const [makeBookingVar, setMakeBookingVar] = useState(
    initialState.booleans.makeBookingVar
  );
  const [singleBookingVar, setSingleBookingVar] = useState(
    initialState.booleans.singleBookingVar
  );
  const [bookingsListVar, setBookingsListVar] = useState(
    initialState.booleans.bookingsListVar
  );
  const [bookingId, setBookingId] = useState(initialState.bookingId);

  return (
    <BookingContext.Provider
      value={{
        bookingData,
        setBookingData,
        makeBookingVar,
        singleBookingVar,
        setMakeBookingVar,
        setSingleBookingVar,
        bookingsListVar,
        setBookingsListVar,
        bookingId,
        setBookingId,
      }}
    >
      <>{props.children}</>
    </BookingContext.Provider>
  );
};

export default BookingProvider;
