import * as types from './actionTypes';
import { getUserProfile, updateUserProfile } from '../library/api/apiCalls';

// This action dispatches on click of "title" save button
export const updateTitle = async (dispatch, user, data) => {
  const response = await updateUserProfile('title', user, data);
  dispatch({
    type: types.SET_TITLE,
    // payload : response.title
  });
};

// This action dispatches on change of "title" input
export const changeTitle = (dispatch, data) => {
  dispatch({
    type: types.CHANGE_TITLE,
    payload: data,
  });
};

// This action dispatches on click of "description" save button
export const updateDescription = async (dispatch, user, data) => {
  const response = await updateUserProfile('content', user, data);
  dispatch({
    type: types.SET_DESCRIPTION,
    // payload : response.content
  });
};

// This action dispatches on change of "description" input
// data parameter should be e.target.value
export const changeDescription = (dispatch, data) => {
  dispatch({
    type: types.CHANGE_DESCRIPTION,
    payload: data,
  });
};

export const fetchUserData = async (dispatch, user) => {
  const response = await getUserProfile(user);
  dispatch({
    type: types.FETCH_DATA,
    payload: response,
  });
};
