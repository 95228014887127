import { openNotificationWithIcon } from 'library/notificationAlerts';
import {
  USER_PROFILE_PATH,
  GIG_CREATE_PATH,
  GIG_UPDATE_PATH,
  USER_LOCATION_INFO_PATH,
  S3_PRE_SIGNED_URL,
} from 'settings/constant';
import axiosInstance from '../hooks/useAxios';

export async function getUserProfile(user) {
  try {
    const response = await axiosInstance.get(USER_PROFILE_PATH(user.id));
    return response.data.profile;
  } catch (error) {
    console.error(error);
  }
}
export async function getUserLocationInfo() {
  try {
    const response = await axiosInstance.get(USER_LOCATION_INFO_PATH);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateUserProfile(formField, user, updatedContent) {
  try {
    const response = await axiosInstance.post(USER_PROFILE_PATH(user.id), {
      [formField]: updatedContent,
    });
    console.log('post', response);
  } catch (error) {
    console.error(error);
  }
}

// todo delete or change this
export async function getGigData(url) {
  try {
    const response = await axiosInstance.get(url);
  } catch (err) {
    console.error(err);
  }
}

export async function createGig(user_id, data) {
  try {
    const response = await axiosInstance.post(GIG_CREATE_PATH(user_id), {
      ...data,
      // todo remove hard coded data
      musicType: 'EDM',
      keywords: 'david',
      locationInfo: {
        formattedAddress: 'London / UK',
      },
      youtube_urls: [
        'https://www.youtube.com/embed/bSAnTxBreJw',
        'https://www.youtube.com/embed/ZWEDsJcI3sM',
        'https://www.youtube.com/embed/NcK8Zyzg5eA',
      ],
      details: { type: 'Singer(solo/duo)', timezone: 'GMT-5' },
      features: { genres: ['Acoustic', 'Pop/Rock'], languages: ['English'] },
      rating: 4,
      ratingCount: 23,
      disabled: 'false',
    });
  } catch (err) {
    console.error(err);
  }
}

export async function updateGig(userId, gigId, data) {
  try {
    const response = await axiosInstance.put(
      GIG_UPDATE_PATH(userId, gigId),
      data
    );
  } catch (err) {
    console.error(err);
  }
}

export async function makeBooking(
  userId,
  eventType,
  gigId,
  date,
  startTime,
  numOfHrs,
  message = ''
) {
  try {
    if (!gigId) {
      console.log('no gig id thrown from makeBookings()');
      throw 'no_gig_id';
    }
    if (eventType === 'MESSAGE' && !message) {
      throw 'empty_message';
    }
    const response = await axiosInstance.post(
      `/api/account/${userId}/book/${gigId}`,
      {
        date: date,
        startTimeISO: startTime,
        numberOfHours: numOfHrs,
        bookingEvent: {
          message: message,
          eventType: eventType,
        },
      }
    );
    if (eventType === 'RESERVED') {
      window.location.href = response.data.stripeLink;
    }
    if (eventType === 'MESSAGE') {
      openNotificationWithIcon('success');
    }
  } catch (err) {
    if (err === 'empty_message') {
      openNotificationWithIcon(
        'error',
        'You are trying to send an empty message!'
      );
    }
    if (err === 'no_gig_id') {
      openNotificationWithIcon('error');
    }
  }
}

export async function getS3PreSignedUrl(fileName, user) {
  try {
    const response = await axiosInstance.post(S3_PRE_SIGNED_URL(user.id), {
      fileName: fileName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
