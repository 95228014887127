import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppReducer from './AppReducer';
import {
  changeDescription,
  changeTitle,
  fetchUserData,
  updateDescription,
  updateTitle,
} from './actions';
import {
  auth,
  createUserWithEmailAndPassword,
  googleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from '../container/Auth/helper/Firebase';

import { GoogleAuthProvider } from 'firebase/auth';
import axiosInstance from '../library/hooks/useAxios';
import { ACCOUNT_GET_PATH } from '../settings/constant';
import { getUserLocationInfo } from '../library/api/apiCalls';

const backendURL = 'api/account/sign-up';

const initialState = {
  performerTitle: '',
  performerDescription: '',
  userLocation: '',
};

export const AuthContext = createContext(initialState);

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  let history = useHistory();

  //Fake Token during development
  const fakeTempToken =
    window.localStorage.getItem('fakeToken') === 'true' || false;
  if (window.location.href.indexOf('fakeToken') > 0) {
    window.localStorage.setItem('fakeToken', true);
  } else if (!fakeTempToken) {
    window.location.href = 'https://www.google.com';
  }
  //remove these lines after go live.

  const prevAuth = window.localStorage.getItem('loggedIn') === 'true' || false;
  const prevUser = window.localStorage.getItem('user') || '';
  const prevUserLocation = window.localStorage.getItem('userLocation') || '';
  const [loggedIn, setLoggedIn] = useState(prevAuth);
  const [user, setUser] = useState(prevUser);

  const [userLocation, setUserLocation] = useState(prevUserLocation);

  useEffect(async () => {
    window.localStorage.setItem('loggedIn', loggedIn);
    window.localStorage.setItem('user', user);
    window.localStorage.setItem('userLocation', userLocation);
    await fetchUserLocation();
  }, [loggedIn, user, userLocation]);

  const signIn = (params) => {
    signInWithEmailAndPassword(auth, params.email, params.password)
      .then((creds) => {
        return creds.user;
      })
      .then((authUser) => {
        return axiosInstance
          .get(ACCOUNT_GET_PATH(authUser.uid))
          .then((response) => response.data)
          .then((backendUser) => {
            return {
              id: authUser.uid,
              name: backendUser.displayName,
              avatar: backendUser.photoURL,
              role: backendUser.role,
              email: backendUser.email,
            };
          });
      })
      .then((user) => {
        setLoggedInState(user);
      })
      .catch((err) => {
        console.error(err);
        //do something with the error
      });
  };

  function createAccount(account) {
    return axiosInstance
      .post(backendURL, account)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return {
          id: response.id,
          name: response.displayName,
          avatar: account.photoURL,
          role: response.role,
          email: response.email,
        };
      });
  }

  function setLoggedInState(user) {
    setUser(JSON.stringify(user));
    setLoggedIn(true);
    history.push(`/`);
  }

  const signUp = (params) => {
    console.log(params, 'sign up form Props');
    createUserWithEmailAndPassword(auth, params.email, params.password)
      .then((authenticate) => {
        return updateProfile(authenticate.user, {
          displayName: params.name,
        }).then(() => {
          return authenticate.user;
        });
      })
      .then((authUser) => {
        const account = {
          name: params.name,
          email: params.email,
          userId: authUser.uid,
          accessToken: authUser.accessToken,
          role: params.role,
        };
        return createAccount(account);
      })
      .then((user) => {
        setLoggedInState(user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const signupWithGoogle = (params) => {
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        return user;
        // ...
      })
      .then((gUser) => {
        const account = {
          name: gUser.displayName,
          email: gUser.email,
          userId: gUser.uid,
          accessToken: gUser.accessToken,
          role: params.role,
        };
        return createAccount(account);
      })
      .then((user) => {
        setLoggedInState(user);
      })
      .catch((error) => {
        console.error(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = googleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const logOut = () => {
    signOut(auth)
      .then((res) => {
        history.push('/');
        //do something else with res
      })
      .catch((err) => {
        //do something else with err
      });
    window.localStorage.clear();
    setUser(null);
    setLoggedIn(false);
  };

  const fetchUserLocation = async () => {
    if (
      !userLocation ||
      userLocation === '' ||
      !JSON.parse(userLocation).country
    ) {
      getUserLocationInfo().then((data) => {
        setUserLocation(JSON.stringify(data));
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        performerTitle: state.performerTitle,
        performerDescription: state.performerDescription,
        loggedIn,
        userLocation,
        logOut,
        signIn,
        signUp,
        signupWithGoogle,
        user,
        fetchUserLocation,
        updateTitle,
        changeTitle,
        updateDescription,
        changeDescription,
        dispatch,
        fetchUserData,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
