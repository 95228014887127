import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import SingleGigCRUDProvider from './context/SingleGigCRUDContext/GigProvider';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_GIGS_PAGE,
  SINGLE_GIG_PAGE,
  ADD_GIG_PAGE,
  MUSICIAN_GIGS_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_PAGE,
  LIVE_STREAM_PLAYBACK_PAGE,
  CREATOR_SIGNUP_PAGE,
  ROLE_CREATOR,
  ROLE_CUSTOMER,
  EDIT_GIG_PAGE,
  MAKEBOOKING,
  BOOKINGSLIST,
} from './settings/constant';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/SignIn/SignIn'
        ),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/SignUp/SignUp'
        ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: CREATOR_SIGNUP_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/CreatorSignUp/SignUp'
        ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/ForgetPassword'
        ),
      loading: Loading,
      modules: ['ForgetPassword'],
    }),
  },
  {
    path: `${SINGLE_GIG_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: LISTING_GIGS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
      loading: Loading,
      modules: ['Privacy'],
    }),
  },
];

/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ './container/AddListing/AddListing'
    ),
  loading: Loading,
  modules: ['AddListing'],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

const ProfilePage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ViewGigsPage" */ './container/Agent/AccountDetails/ViewGigsPage'
    ),
  loading: Loading,
  modules: ['ViewGigsPage'],
});

const LiveStreamPlayback = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Pricing" */ './container/StreamPlayer/StreamPlayer'
    ),
  loading: Loading,
  modules: ['StreamPlayer'],
});

const GigEdit = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "GigEdit" */ './container/Agent/UpdateGig/GigEdit'
    ),
  loading: Loading,
  modules: ['GigEdit'],
});

const MyConversations = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MyConversations" */ './container/Bookings/Conversations/MyConversations'
    ),
  loading: Loading,
  modules: ['MyConversations'],
});

const Bookings = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bookings" */ './container/Bookings/Bookings'),
  loading: Loading,
  modules: ['Bookings'],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

const MusicianPrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useContext(AuthContext);
  const authUser = user && JSON.parse(user);
  return (
    <Route
      render={(props) =>
        // todo should redirect to 403 page instead of login page
        loggedIn && authUser.role === ROLE_CREATOR ? (
          <Component {...props} />
        ) : (
          <Redirect to={LOGIN_PAGE} />
        )
      }
      {...rest}
    />
  );
};

const CustomerPrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useContext(AuthContext);
  const authUser = user && JSON.parse(user);
  return (
    <Route
      render={(props) =>
        // todo should redirect to 403 page instead of login page
        loggedIn && authUser.role === ROLE_CUSTOMER ? (
          <Component {...props} />
        ) : (
          <Redirect to={LOGIN_PAGE} />
        )
      }
      {...rest}
    />
  );
};
/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => {
          // if (path === MUSICIAN_GIGS_PAGE) {
          //   return (
          //     <GigProvider>
          //       <Route
          //         key={path}
          //         path={path}
          //         exact={exact}
          //         component={component}
          //       />
          //     </GigProvider>
          //   );
          // }
          return (
            <Route key={path} path={path} exact={exact} component={component} />
          );
        })}
        <MusicianPrivateRoute path={ADD_GIG_PAGE} component={AddListing} />
        <MusicianPrivateRoute
          path={`${EDIT_GIG_PAGE}/:slug`}
          component={GigEdit}
        />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        <ProtectedRoute path={MUSICIAN_GIGS_PAGE} component={ProfilePage} />
        <ProtectedRoute path={MAKEBOOKING} component={Bookings} />
        <ProtectedRoute path={BOOKINGSLIST} component={Bookings} />
        <ProtectedRoute path={`${BOOKINGSLIST}/:id`} component={Bookings} />
        <CustomerPrivateRoute
          path={LIVE_STREAM_PLAYBACK_PAGE}
          component={LiveStreamPlayback}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
