import React, { useContext, useState, useRef } from 'react';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { NavLink, withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import {
  MUSICIAN_GIGS_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  ADD_GIG_PAGE,
  ROLE_CREATOR,
  ROLE_CUSTOMER,
  BOOKINGSLIST,
} from 'settings/constant';

const ProfileMenu = ({ avatar, history }) => {
  const { logOut, loggedIn, user } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const authUser = user && JSON.parse(user);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logOut();
    history.push('/');
  }

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        {avatar}
      </div>

      <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
        {/* <Menu.Item onClick={closeDropdown} key="0">
          <NavLink to={MUSICIAN_GIGS_PAGE}>My Gigs</NavLink>
        </Menu.Item> */}
        {loggedIn && (
          <Menu.Item onClick={closeDropdown} key="0">
            {authUser.role === ROLE_CREATOR && (
              <NavLink to={ADD_GIG_PAGE}>Add Gig</NavLink>
            )}
            {authUser.role === ROLE_CUSTOMER && (
              <NavLink to={BOOKINGSLIST}>My Bookings</NavLink>
            )}
          </Menu.Item>
        )}
        <Menu.Item onClick={closeDropdown} key="1">
          <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <button onClick={handleLogout}>Log Out</button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(ProfileMenu);
