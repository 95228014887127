import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  LISTING_GIGS_PAGE,
  PRIVACY_PAGE,
  LIVE_STREAM_PLAYBACK_PAGE,
  MUSICIAN_GIGS_PAGE,
} from 'settings/constant';

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${HOME_PAGE}`}>Home</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${LISTING_GIGS_PAGE}`}>Musicians</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${LIVE_STREAM_PLAYBACK_PAGE}`}>
          My Upcoming Live Streams
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${PRIVACY_PAGE}`}>Privacy</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${MUSICIAN_GIGS_PAGE}`}>Agent</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;
