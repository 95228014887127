import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";

const config = {
  apiKey: "AIzaSyDxKP_x7fI7Mx0TRbk87etAJrhXnCuJPds",
  authDomain: "livemusico.firebaseapp.com",
  projectId: "livemusico",
  storageBucket: "livemusico.appspot.com",
  messagingSenderId: "528333956387",
  appId: "1:528333956387:web:58578c764a293dfe73d896",
  measurementId: "G-0P1YEJ12R8",
};

const app = initializeApp(config);

const auth = getAuth();
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
};
