// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_GIGS_PAGE = '/musicians';
export const SINGLE_GIG_PAGE = '/gig';

// Agent Profile Page Section
export const MUSICIAN_GIGS_PAGE = '/my-gigs';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';

// Other Pages
export const LIVE_STREAM_PLAYBACK_PAGE = '/my-live-stream';
export const PRIVACY_PAGE = '/privacy';
export const ADD_GIG_PAGE = '/add-gig';
export const EDIT_GIG_PAGE = '/my-gigs/edit';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CREATOR_SIGNUP_PAGE = '/creator-join';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

// Role
export const ROLE_CREATOR = 'CREATOR';
export const ROLE_CUSTOMER = 'CUSTOMER';

export const backendURL = 'https://livemusico.com/';
export const USER_PROFILE_PATH = (uid) => `api/account/${uid}/profile`;

export const GIG_SEARCH_PATH =
  'api/gigs/search?musicType=default&keywords=default';
export const DYNAMIC_SEARCH_PATH = (query) => `api/gigs/search${query}`;
export const GIG_CREATE_PATH = (uid) => `api/account/${uid}/gig`;
export const GIG_UPDATE_PATH = (uid, gigId) =>
  `/api/account/${uid}/gig/${gigId}`;
export const ACCOUNT_GET_PATH = (uid) => `api/account/${uid}/profile`;
export const USER_GIG_PATH = (uid) => `api/account/${uid}/gigs`;
export const CUSTOMER_BOOKINGS_PATH = (customer_id) =>
  `api/account/${customer_id}/bookings`;
export const CREATOR_BOOKINGS_PATH = (creator_id) =>
  `api/account/${creator_id}/gig-bookings`;
export const BOOKING_EVENTS_PATH = (customer_id, booking_id) =>
  `api/account/${customer_id}/bookings-events/${booking_id}`;
export const USER_LOCATION_INFO_PATH = `api/user-location/`;
export const COUNTRY_FLAG = (countryISO) =>
  `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryISO}.svg`;
export const S3_PRE_SIGNED_URL = (uid) =>
  `/api/account/${uid}/upload-signer-urls`;

export const MAKEBOOKING = '/make-booking';
export const BOOKINGSLIST = '/bookings';
