import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import { fetchGigData } from './actions';

const initialState = {
  gigTitle: null,
  gigDescription: null,
};

export const GigContext = createContext(initialState);

const GigsProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <GigContext.Provider
      value={{
        gigTitle: state.gigTitle,
        gigDescription: state.gigDescription,
        dispatch,
        fetchGigData,
      }}
    >
      <>{props.children}</>
    </GigContext.Provider>
  );
};

export default GigsProvider;
